import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
    // 发送短信验证码
    async getSmsCode(mobile) {
        return await toGet(weburl + "get_sms_code/", mobile)
    },

    // 平台登录
    async indexLogin(data) {
        return await toPost(adminurl + "index/login", data)
    },

    // 查询今日订单数据
    async showTodayOrdersInfo() {
        return await toGet(adminurl + "index/show_today_orders_info/", store.getters.getShopId)
    },
    // 查询今日服务单数据
    async showTodayServeOrdersInfo() {
        return await toGet(adminurl + "index/show_today_server_orders_info/", store.getters.getShopId)
    },
    // 查询今日新增用户
    async showTodayNewPeople() {
        return await toGet(adminurl + "index/show_today_new_people/", store.getters.getShopId)
    },
    // 查询今日采购单数据
    async showTodayRoleOrdersInfo() {
        return await toGet(adminurl + "index/show_today_role_orders_info/", store.getters.getShopId)
    },
    // 查询今日提现
    async showTodayBalanceTx() {
        return await toGet(adminurl + "index/show_today_balance_tx", store.getters.getShopId)
    },
    // 查询柱状图统计
    async goodsSaleCurve(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "index/goods_sale_curve", data)
    },
    // 查询曲线图统计
    async salesCurve(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "index/sales_curve", data)
    },
    // 查询饼图统计
    async goodsCateCurve(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "index/goods_cate_curve", data)
    },


    // 展示门店管理员列表
    async showAdmins() {
        return await toGet(weburl + "shop/show_admins/", store.getters.getShopId)
    },
    // 新增门店管理员账号
    async addAdmin(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/add_admin", data)
    },
    // 修改管理员信息
    async modifyAdmin(data) {
        return await toPost(weburl + "shop/modify_admin", data)
    },
    // 修改管理员账号状态
    async modifyAdminStatus(data) {
        return await toPost(weburl + "shop/modify_admin_status", data)
    },
    // 门店后台登录
    async adminLogin(data) {
        return await toPost(weburl + "shop/admin_login", data)
    },
    
    // 查询日志
    async showOperationLos(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "system/show_operation_los", data)
    },
}
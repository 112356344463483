import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;


// 导出
export default {
    /**
     * 
     * 专区
     */
    // 查询专区
    async Types() {
        return await toGet(weburl + "shop/good/label/show/", store.getters.getShopId)
    },
    // 新增专区
    async addTypes(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/label/add", data)
    },
    // 修改专区
    async editTypes(data) {
        return await toPost(weburl + "shop/good/label/update", data)
    },
    // 修改专区状态
    async typesStatus(data) {
        return await toPost(weburl + "shop/good/label/update_label_status", data)
    },
    // 删除专区
    async deleteTypes(id) {
        return await toGet(weburl + "shop/good/label/delete/", id)
    },

    /**
     * 
     * 类别
     */
    // 查询类别
    async Class() {
        return await toGet(weburl + "shop/good/category/show/", store.getters.getShopId)
    },
    // 新增类别
    async addClass(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/category/add", data)
    },
    // 修改类别
    async editClass(data) {
        return await toPost(weburl + "shop/good/category/update", data)
    },
    // 删除类别
    async deleteClass(id) {
        return await toGet(weburl + "shop/good/category/delete/", id)
    },

    /**
     * 
     * 商品规格
     */
    // 查询规格
    async Specs() {
        return await toGet(weburl + "shop/good/spec/show/", store.getters.getShopId)
    },
    // 新增规格
    async addSpecs(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/spec/add", data)
    },
    // 修改规格
    async editSpecs(data) {
        return await toPost(weburl + "shop/good/spec/update", data)
    },
    // 删除规格
    async deleteSpecs(data) {
        return await toPost(weburl + "shop/good/spec/delete", data)
    },

    /**
     * 
     * 商品
     */
    // 查询商品列表
    async goodsList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/show", data)
    },
    // 商品上架或下架
    async goodsStatus(data) {
        return await toPost(weburl + "shop/good/modify_good_status", data)
    },
    // 删除商品
    async deleteGoods(id) {
        return await toGet(weburl + "shop/good/delete/", id)
    },
    // 商品规格列表
    async goodsBase(id) {
        return await toGet(weburl + "shop/good/good_base/", id)
    },
    // 上下架规格
    async goodsBaseStatus(data) {
        return await toPost(weburl + "shop/good/modify_good_base_status", data)
    },
    // 修改商品信息
    async updateGoods(data) {
        return await toPost(weburl + "shop/good/update", data)
    },
    // 修改商品规格
    async updateGoodsBase(data) {
        return await toPost(weburl + "shop/good/update_base", data)
    },
    // 新增商品规格信息
    async addGoodsBase(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/add_base", data)
    },
    // 查询商品评论
    async goodsComments(id) {
        return await toGet(weburl + "shopgood/product_comments/", id)
    },
    // 回复评论
    async replyComments(data){
        return await toPost(weburl + "shopgood/shop_comments", data)
    },
    // 删除评论
    async delComments(id){
        return await toGet(weburl + "shopgood/del_comments/", id)
    },
    // 新增商品规格信息
    async addGoods(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/add", data)
    },

    // 查询后台需要的商品
    async getGoodsToConfig() {
        return await toGet(adminurl + "system/get_goods_to_config/", store.getters.getShopId)
    },



    // 查询团购商品
    async teamGoodsList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/teamwork/show", data)
    },
    // 添加团购商品
    async addTeamGoods(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/teamwork/add", data)
    },
    // 修改团购商品信息
    async updateTeamGoods(data) {
        return await toPost(weburl + "shop/good/teamwork/update", data)
    },
    // 删除团购商品
    async deleteTeamGoods(id) {
        return await toPost(weburl + "shop/good/teamwork/delete", id)
    },



    /*
        秒杀
    */
    // 查询秒杀活动列表
    async activityList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "activity/show", data)
    },
    // 创建秒杀活动
    async addActivity(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "activity/add", data)
    },
    // 修改秒杀活动
    async updateActivity(data) {
        return await toPost(adminurl + "activity/update", data)
    },

    // 查询秒杀活动商品列表
    async activityGoodsList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/ms/show", data)
    },
    // 为秒杀活动添加商品
    async addActivityGoods(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/good/ms/add", data)
    },
    // 修改秒杀商品信息
    async updateActivityGoods(data) {
        return await toPost(weburl + "shop/good/ms/update", data)
    },
    // 移除秒杀商品
    async deleteActivityGoods(id) {
        return await toGet(weburl + "shop/good/ms/delete/", id)
    },
}
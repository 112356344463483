import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
    // 查询文章列表
    async articleList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        // /" , shopId
        return await toPost(adminurl + "article/show", data)
    },

}
import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      shopId: sessionStorage.getItem("shopId") ? sessionStorage.getItem("shopId") : 0,
      menuActive: "index",
      currentPath: ["Monitor", "首页"],
      adminUserInfo: sessionStorage.getItem("adminUserInfo")
        ? JSON.parse(sessionStorage.getItem("adminUserInfo"))
        : {},
      token: "",
      pageInfos: {},
      msg: 0
    };
  },
  getters: {
    getAdmin(state) {
      return state.adminUserInfo;
    },
    Gtoken(state) {
      return state.token;
    },
    getPageInfos(state) {
      return state.pageInfos;
    },
    getMsg(state) {
      return state.msg;
    },
    getShopId(state) {
      return state.shopId;
    },
    getLogo(state) {
      return state.adminUserInfo.logo;
    },
    getAdminId(state) {
      return state.adminUserInfo.id;
    },
  },
  mutations: {
    setMsg(state, newVal) {
      state.msg = newVal;
    },
    setPage(state, newVal) {
      state.pageInfos = newVal;
      console.log(newVal);
    },
    changeBreadCrumb(state, newVal) {
      state.currentPath = newVal;
    },
    changeMenu(state, newVal) {
      state.menuActive = newVal;
    },
    // 修改头像
    changeavator(state, newVal) {
      console.log('修改新头像', newVal)
      state.adminUserInfo.logo = newVal
      console.log('修改成功', state.adminUserInfo)
      let data = JSON.stringify(state.adminUserInfo);
      sessionStorage.setItem("adminUserInfo", data);
    },
    // 储存登录token
    loginToken(state, newVal) {
      state.token = newVal;
    },
    // 储存登录信息
    login(state, newVal) {
      let data = JSON.stringify(newVal);
      sessionStorage.setItem("adminUserInfo", data);
      console.log('登录的用户信息', newVal)
      state.adminUserInfo = newVal;
    },
    // 储存门店ID
    saveShopId(state, newVal) {
      console.log('保存门店id', newVal)
      state.shopId = newVal;
      sessionStorage.setItem("shopId", newVal);
      console.log(sessionStorage.getItem("shopId"))
    }
  },
  actions: {},
  modules: {},
});

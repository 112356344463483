import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
    // 查询用户列表
    async userList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "user/show", data)
    },
    // 手动新增用户
    async register(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "user/regist_serve_account", data)
    },
    // 修改用户账号状态
    async userStatus(data) {
        return await toPost(adminurl + "user/freeze_user", data)
    },
    // 重置用户账号密码
    async resetPwd(id) {
        return await toGet(adminurl + "user/reset_pwd/", id)
    },
    // 重置用户交易密码
    async resetPayPwd(id) {
        return await toGet(adminurl + "user/reset_pay_pwd/", id)
    },

    // 查询用户收货地址
    async address(id) {
        return await toGet(weburl + `user/sel_user_address/`, id)
    },
    // 查询位置是否超出门店的线下服务范围
    async verifyServeArea(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + `shop/verify_serve_area`, data)
    },
    // 查询门店优惠券
    async ticketList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "property/ticket/show", data)
    },
    // 删除门店优惠券
    async deleteTicket(id) {
        return await toGet(adminurl + "property/ticket/delete/", id)
    },
    // 新增门店优惠券
    async addTicket(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "property/ticket/add", data)
    },
    // 修改门店优惠券
    async updateTicket(data) {
        return await toPost(adminurl + "property/ticket/update", data)
    },
    // 查询可用的门店优惠券
    async availableTicket() {
        return await toGet(adminurl + "property/ticket/sel/", store.getters.getShopId)
    },

    // 查询用户的优惠券
    async userTicketInfo(id) {
        return await toGet(weburl + "wallet/user_ticket_info/", id)
    },


    // 修改用户资产信息吗，充值或扣除用户资产
    async modifyUserResource(data) {
        return await toPost(adminurl + "user/modify_user_resource", data)
    },
    // 给用户充值优惠券
    async addUserTicket(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "user/add_user_ticket", data)
    },


    // 查询用户余额明细
    async userBalanceLog(data) {
        return await toPost(adminurl + "user/sel_user_balance_log", data)
    },
    // 查询用户积分明细
    async userSaltLog(data) {
        return await toPost(adminurl + "user/sel_user_salt_log", data)
    },
}